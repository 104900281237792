import "./App.css";
import { firebaseApp } from "./firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  setDoc,
  getFirestore,
  query,
  where,
  onSnapshot,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
const App = () => {
  const auth = getAuth(firebaseApp);
  const db = getFirestore(firebaseApp);
  const [user, loading] = useAuthState(auth);
  const [reviewsList, setReviewsList] = useState();
  const [message, setMessage] = useState("");
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [lang, setLanguage] = useState("");

  const q = query(
    collection(db, "Reviews"),
    where("checked", "==", false),
    orderBy("createdOn", "desc")
  );
  const logUser = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, userData.email, userData.password)
      .then((userCredential) => {})
      .catch((error) => {
        setMessage(error.message.slice(10, error.message.length - 1));
        setTimeout(() => {
          setMessage("");
        }, 2000);
      });
  };
  const deleteReview = (review) => {
    if (window.confirm("Delete review?")) {
      deleteDoc(doc(db, "Reviews", review.docId.id));
    }
  };
  useEffect(async () => {
    onSnapshot(q, (querySnapshot) =>
      setReviewsList(querySnapshot.docs.map((doc) => doc.data()))
    );
  }, []);
  const checkReview = (review) => {
    if (window.confirm("Verify review?")) {
      const cityRef = doc(db, "Reviews", review.docId.id);
      setDoc(cityRef, { checked: true }, { merge: true });
    }
  };
  return (
    <div className="App">
      {!loading && !user ? (
        <form onSubmit={(e) => logUser(e)} className="login">
          <h3>TruckBAN Admin Panel</h3>
          <input
            type="email"
            placeholder="Email"
            required
            onChange={(e) =>
              setUserData((data) => ({ ...data, email: e.target.value }))
            }
          />
          <input
            type="password"
            placeholder="Password"
            required
            onChange={(e) =>
              setUserData((data) => ({ ...data, password: e.target.value }))
            }
          />
          <p>{message}</p>
          <button type="submit">Login</button>
        </form>
      ) : (
        <div className="list">
          <p>Unchecked reviews:</p>
          <br />
          <select onChange={(e) => setLanguage(e.target.value)}>
            <option value="">Original Language</option>
            <option value="en">English</option>
            <option value="bg">Bulgarian</option>
            <option value="cs">Czech</option>
            <option value="de">German</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
            <option value="ro">Romanian</option>
            <option value="ru">Russian</option>
            <option value="uk">Ukrainian</option>
            <option value="tr">Turkish</option>
          </select>
          <ul>
            {reviewsList &&
              reviewsList.length !== 0 &&
              reviewsList.map((review, index) => (
                <li key={index}>
                  {review.translated && (
                    <span>
                      Comment:{" "}
                      {lang === "" ? review.comment : review.translated[lang]}
                    </span>
                  )}
                  <span>
                    Locations: {review.lat} : {review.lng}
                  </span>
                  <span>
                    Added: {review.createdOn.toDate().toLocaleString("ro")}
                  </span>
                  <div className="buttons">
                    <p onClick={() => checkReview(review)}>Verify</p>
                    <p onClick={() => deleteReview(review)}>Delete</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default App;
